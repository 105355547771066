export const FAQ_QUESTIONS_ZH = [
  {
    question: "DRC代币是什么？",
    answer: [
      "DRC是一种ERC20代币，它的存在是为了成为供应有限且通货膨胀率为零的分散式数字化价值存储。DRC也是一项货币实验，其目的用于查清楚全球经济的衰退和对去中心式金融工具不断增长的需求是如何影响其在全球的采用和使用。",
    ],
  },
  {
    question: "DRC是如何创新的？",
    answer: [
      "代币分配的模型是创新的，因为模型中没有ICO / IEO。并且100％的代币都是由去中心化的Uniswap交易所发行的。没有一个代币被开挖过，也不会铸造更多的代币。这个由DRC社区管理的项目是完全去中心化的。",
    ],
  },
  {
    question: "如何去定义DRC代币的价值？",
    answer: [
      "DRC代币的价值取决于网络效应及其在全球范围内的采用。需要稳定的表现史来证明它能够成为一种价值储藏的潜力。 而DRC代币的额外用途（例如安全性和隐私功能）也可能会为其增加价值主张。",
    ],
  },
  {
    question: "DRC是如何具有自然的通缩模型的？",
    answer: [
      "随着时间长了，由于发送错误等原因，一些DRC代币很可能会丢失。因此，总的DRC代币供应将久而久之减少。举个例子的话，根据Chainalysis的研究，已经有大约400万比特币丢失并且无法恢复。",
    ],
  },
  {
    question: "为什么DRC是一种<em>潜在的</em>数字化价值储藏？",
    answer: [
      "当前，DRC尚不具备所有必要的SoV属性，并且由于其在全球范围内的普及程度较低且缺乏表现史，因此无法与黄金或比特币进行比较。DRC是一项长期的货币实验。DRC需要稳定的增长和全球认可，才能成为真正的价值储藏。",
    ],
  },
  {
    question: "林迪效应是如何适用于DRC的？",
    answer: [
      "林迪效应是一种理论，认为某些不会自然消亡的事物（例如技术或思想）的预期寿命与它们目前已经存在的时间成正比，因此，每增加一个生存期，则意味着剩余的预期寿命更长。此概念是适用于DRC潜力作为一种数字化价值储藏的。",
    ],
  },
  {
    question: "我该不该投资DRC？",
    answer: [
      "这取决于您的风险承受能力、投资目标、投资时间表，净资产和区块链市场知识。 DRC白皮书中明确指出，由于越来越多的人相信DRC令牌的内在价值和效用，因此可能会由于网络效应而呈现出DRC的代币价值。与此同时，如果DRC代币没有被全球采用作为一种数字价值储藏，它就可能会失去其价值。",
    ],
  },
];
